import { getToken } from '@/services/device.js'

export const userGuard = async (to, from, next) => {
  const token = await getToken()
  if (token) {
    next()
  } else {
    window.location.href = "/user-type-selector"
  }
}

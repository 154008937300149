export const readRegisterError = errorCode => {
  switch (errorCode) {
    case 'REGISTER_ERROR_003':
      return 'Por favor, introduzca un mínimo de 6 carácteres en el campo de contraseña'
    default:
      return 'Error registrando al usuario'
  }
}

export const readLoginError = errorCode => {
  switch (errorCode) {
    case 'LOGIN_ERROR_003':
      return 'Por motivos de seguridad hemos deshabilitado temporalmente el acceso a su cuenta. Por favor, inténtelo pasado unas horas.'
    default:
      return 'Error en usuario y/o contraseña'
  }
}

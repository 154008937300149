import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'

import 'animate.css'

import { IonicVue } from '@ionic/vue'
import { createI18n } from 'vue-i18n'

import en from '@/messages/en.json'
import es from '@/messages/es.json'

const i18n = createI18n({
  locale: 'es',
  messages: {
    en,
    es,
  },
})

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

const app = createApp(App).use(IonicVue).use(i18n).use(router)

router.isReady().then(() => {
  app.mount('#app')
})

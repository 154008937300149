import _ from 'underscore'
import endpointCalculator from '@/services/endpoint_calculator'
import axios from 'axios'

const endpoint = endpointCalculator()

export default {
  getEndpoint() {
    return endpoint
  },
  notifications: async function (token) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/notifications/`, { token })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  follow: async function (token, target) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/follow/`, { token, target })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  unfollow: async function (token, target) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/unfollow/`, { token, target })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  list: async function (token, page = 1) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/media-list/`, { token, page })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  suggested: async function (token) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/suggested/`, { token })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  activeChats: async function (token) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/active-chats/`, { token })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  readChat: async function (token, target) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/read-chat/`, { token, target })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  newChat: async function (token, target, message) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/new-chat/`, { token, target, message })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  lists: async function (token) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/lists/`, { token })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  faqs: async function (token) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/faqs/`, { token })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  search: async function (token, q) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/search/`, { token, q })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  block: async function (token, target) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/block/`, { token, target })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  unblock: async function (token, target) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/unblock/`, { token, target })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  unsubscribe: async function (token, public_key) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/unsubscribe/`, { token, public_key })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  subscribers: async function (public_key) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/subscribers/`, { public_key })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  subscriptions: async function (token) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/subscriptions/`, { token })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  listProfile: async function (token, customer_public_key, page = 1) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/media-list/`, {
          token,
          customer_public_key,
          page
        })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  listSingle: async function (token, media_public_key, page = 1) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/media-list/`, {
          token,
          media_public_key,
          page
        })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  listSingleBySeed: async function (token, seed, page = 1) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/media-list/`, { token, seed, page })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  report: async function (token, public_key, reported_option) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/report/`, {
          token,
          public_key,
          reported_option
        })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  profile: async function (token, public_key) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/profile/`, { token, public_key })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  toggleFav: async function (token, public_key) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/media-fav-trigger/`, { token, public_key })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  toggleBookmark: async function (token, public_key) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/media-bookmark-trigger/`, {
          token,
          public_key
        })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  fakeSubscription: async function (token, subscriptor, months) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/fake-subscription/`, { token, subscriptor, months })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  fakePurchase: async function (token, media) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/fake-purchase/`, { token, media })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  login: async function (username, password) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/login/`, { username, password })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  register: async function (username, password, customer_type) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/register/`, {
          username,
          password,
          customer_type
        })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  me: async function (token) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/me/`, { token })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  recover: async function (username) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/recover/`, { token })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  support: async function (token, text) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/support/`, { token, text })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  billingSupport: async function (token, username, email, text, request) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/billing-support/`, { token, username, email, text, request })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  updateIBAN: async function (token, iban) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/update/`, { token, iban })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  updateMedia: async function (token, public_key, data) {
    const postData = _.extend(data, { token, public_key })
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/media-update/`, postData)
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  updateNotifications: async function (
    token,
    notification_new_comment,
    notification_new_like,
    notification_new_stream,
    notification_new_dm,
    public_profile
  ) {
    return axios.post(`${endpoint}/v1/core/update/`, {
      token,
      notification_new_comment: notification_new_comment ? '1' : '0',
      notification_new_like: notification_new_like ? '1' : '0',
      notification_new_stream: notification_new_stream ? '1' : '0',
      notification_new_dm: notification_new_dm ? '1' : '0',
      public_profile: public_profile ? '1' : '0'
    })
  },
  updateProfile: async function (token, fullname, location, bio, username, phone, alias) {
    return axios.post(`${endpoint}/v1/core/update/`, {
      token,
      fullname,
      location,
      bio,
      username,
      phone,
      alias
    })
  },
  addComent: async function (token, content, public_key, reply) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/comment-add/`, { token, content, public_key, reply })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  listComments: async function (token, public_key) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/comment-list/`, { token, public_key })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  updateConfig1: async function (token, username, phone) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/update/`, { token, username, phone })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  },
  updateConfig2: async function (token, password) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoint}/v1/core/update/`, { token, password })
        .then(resolve)
        .catch(err => {
          resolve(null)
        })
    })
  }
}

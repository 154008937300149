import { isEmpty, isUndefined } from 'underscore'
import { Device } from '@capacitor/device'
import { Storage } from '@capacitor/storage'

export const storeValue = async (key, value) => {
  await Storage.set({
    key,
    value: JSON.stringify(value)
  })
}

export const getValue = async key => {
  const { value } = await Storage.get({ key })
  if (isUndefined(value) || isEmpty(value)) return null
  return JSON.parse(value)
}

export const storeIds = async () => {
  const deviceInfo = await Device.getInfo()
  const deviceId = await Device.getId()
  const uuid = deviceId.uuid
  const platform = deviceInfo.platform

  await storeValue('deviceId', `${platform}-${uuid}`)
}

// Proxies
export const removeAll = async () => {
  await setToken(null)
}

export const getDeviceId = async () => {
  return await getValue('deviceId')
}

export const getToken = async () => {
  return await getValue('token')
}

export const setToken = async value => {
  return await storeValue('token', value)
}

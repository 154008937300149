export default {
  methods: {
    toMedia: (public_key, $router) => {
      $router.push({
        name: 'Media',
        params: {
          public_key,
        },
      })
    },
    toProfile: (public_key, $router) => {
      $router.push({
        name: 'Customer',
        params: {
          public_key,
        },
      })
    },
    toSubscription: (public_key, $router) => {
      $router.push({
        name: 'PrePayment',
        params: {
          public_key,
        },
      })
    },
    toPurchase: (public_key, $router) => {
      $router.push({
        name: 'PrePurchase',
        params: {
          public_key,
        },
      })
    },
  },
}

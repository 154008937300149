import { isEmpty } from 'underscore'

export const validEmail = value => {
  const rx = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}')
  return rx.test(value)
}

export const validateConfigurationForm1 = (fullname, username, phone, password, password2) => {
  let valid = true

  if (isEmpty(username)) valid = false
  if (!validEmail(username)) valid = false

  if (!isEmpty(password) && !isEmpty(password2)) {
    // trying to change the password
    if (password != password2) valid = false
    if (password.length < 6) valid = false
  }

  return valid
}
